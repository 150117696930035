<template>
    <div class="area-wrapper">
        <div class="text-center mb-5">
            <h1 class="title--primary">{{ header }}</h1>
            <p class="title--tertiary">{{ subHeader }}</p>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            required: false,
            type: String,
        },
        subHeader: {
            default: '',
            type: String,
        },
    },
};
</script>

<style lang="scss">
.area-wrapper {
    padding: 4rem 0 2rem 0;
}
</style>
