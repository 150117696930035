<template>
    <div class="slide-wrapper mt-4">
        <div class="swiper-container gallery-top">
            <div class="swiper-wrapper">
                <div
                    v-for="(image, index) in images"
                    :key="index"
                    class="swiper-slide position-relative"
                    :style="{
                        backgroundImage: `url(${image.file_url})`,
                    }"
                >
                    <div
                        class="sold-out-detail fw-bold text-center"
                        v-if="check_sold_out"
                    >
                        OUT OF STOCK
                    </div>
                    <div
                        class="position-absolute pt-3 pb-3 pe-3 ps-3 wish-icon"
                        v-if="isLoggedIn"
                    >
                        <font-awesome-icon
                            v-if="hasInWishList(parseInt(product_id))"
                            @click="toggleWish(parseInt(product_id))"
                            :icon="['far', 'heart']"
                            class="active-icon"
                            :id="`active-wish-${parseInt(product_id)}`"
                        />
                        <font-awesome-icon
                            v-else
                            @click="toggleWish(parseInt(product_id))"
                            :icon="['far', 'heart']"
                            :id="`active-wish-${parseInt(product_id)}`"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
                <div
                    v-for="(image, index) in images"
                    :key="index"
                    class="swiper-slide"
                    :style="{ backgroundImage: `url(${image.file_url})` }"
                ></div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import { mapGetters } from 'vuex';
import AddToWishList from '@/mixins/AddToWishlist';

export default {
    mixins: [AddToWishList],

    props: {
        images: {
            type: Array,
            required: true,
        },

        product_id: {
            type: String,
            required: true,
        },

        check_sold_out: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        this.slideInit();
    },

    updated() {
        this.slideInit();
    },

    computed: {
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },

    methods: {
        slideInit() {
            var galleryThumbs = new Swiper('.gallery-thumbs', {
                spaceBetween: 10,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,

                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
            new Swiper('.gallery-top', {
                spaceBetween: 10,

                thumbs: {
                    swiper: galleryThumbs,
                },
            });
        },
    },
};
</script>

<style lang="scss">
.slide-wrapper {
    // height: 600px;

    .swiper-container,
    .swiper-wrapper {
        z-index: unset;
    }

    .active-icon {
        color: red;
        cursor: pointer;
    }

    .swiper-button-next,
    .swiper-button-prev {
        --swiper-navigation-color: #000000;
        background-color: #00000040;

        &::after {
            font-size: 14px;
        }
    }

    .wish-icon {
        background-color: yellow;
        right: 0;
    }
}

.swiper-container {
    width: 100%;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
    padding-top: calc(100% / 10 * 9);
}

.gallery-thumbs {
    padding: 10px 0;
}

.gallery-thumbs .swiper-slide {
    padding-top: calc(100% / 4 * 0.9);
    opacity: 0.4;
}

.gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
}

.sold-out-detail {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: rgba($color: #ffffff, $alpha: 0.7);
    z-index: 20;
    padding: 13px;
}
</style>
