<template>
    <div class="our-store pb-4">
        <div class="bg-store">
            <div class="heading text-center p-5">
                <h1 class="title--primary">
                    {{ $t('home.ourStoreArea.header') }}
                </h1>
                <p class="title--tertiary">
                    {{ $t('home.ourStoreArea.subHeader') }}
                </p>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-5 col-xl-4 offset-xl-1">
                        <div class="card border-0 m-auto mb-5">
                            <img
                                src="@/assets/img/shop/JC.jpg"
                                class="card-img-top"
                                alt="image"
                            />
                            <div class="card-body ps-0">
                                <h5 class="card-title fw-bold pt-3 pb-3">
                                    Shop-1 : PYAE SONE SHIN, Junction City
                                </h5>
                                <p class="card-text">
                                    First Floor (Level-2), C - (007, 008) <br />
                                    Gems &amp; Souvenir Zone (နှစ်ခန်းတွဲ
                                    ဆိုင်ခန်းကျယ်) <br />
                                    Corner of Shwedagon Pagada Road and Bogyoke
                                    Road, Pabedan Township, Yangon.
                                    <br />
                                    Opening Time : 9am to 5pm Daily
                                </p>
                                <p class="pt-1 pb-1 fw-bold">
                                    Phone : 09-785-018-374, 09-780-177-177,
                                    09-760-147-517
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        class="
                            col-md-6 col-lg-5
                            offset-lg-2
                            col-xl-4
                            offset-xl-2
                        "
                    >
                        <div class="card border-0 m-auto mb-5">
                            <img
                                src="@/assets/img/shop/TC.jpg"
                                class="card-img-top"
                                alt="image"
                            />
                            <div class="card-body ps-0">
                                <h5 class="card-title fw-bold pt-3 pb-3">
                                    Shop-2 : PYAE SONE SHIN, Times City
                                </h5>
                                <p class="card-text">
                                    B-211, B-223, B-227, B-229, B-230 <br />
                                    Second Floor, Jewellery Mall, Times City,
                                    <br />
                                    Between Hantharwaddy Road and Kyun Taw Road,
                                    <br />
                                    Kamayut Township, Yangon. <br />
                                    Opening Time : 9am to 4pm Daily
                                </p>
                                <p class="pt-1 pb-1 fw-bold">
                                    Phone : 09-783-122-122, 09-784-122-122
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.our-store {
    background: linear-gradient(
        to bottom,
        var(--color-black) 400px,
        #fff 400px,
        #fff 100%
    );

    .bg-store {
        .heading {
            color: #fff;
        }
    }

    .detail-link {
        text-decoration: none;
        font-size: 15px;
        color: #000;
    }

    @media (max-width: 767.98px) {
        background: var(--color-black);
        color: #fff;

        .card {
            background: var(--color-black);
        }

        .detail-link {
            color: #fff;
        }
    }
}
</style>
