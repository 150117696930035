import { mapGetters } from 'vuex';
import getselectedvariants from '../graphql/mutations/order/getselectedvariants.gql';
const AddToCart = {
    computed: {
        ...mapGetters({
            carts: 'cart/getCarts',
        }),
    },

    methods: {
        addCart(data) {
            if (this.carts && this.carts.length !== 0) {
                this.$store.dispatch('cart/updateCart', data);
            } else {
                this.$store.commit('cart/ADD_CARTS', data);
            }
        },
        updatedCheckedVariants() {
            let product_variant_ids = this.carts.map(res =>
                Number(res.product_variant_id),
            );
            this.$apollo
                .mutate({
                    mutation: getselectedvariants,

                    variables: {
                        product_variant_ids: product_variant_ids,
                    },
                })
                .then(response => {
                    this.carts.map(cart => {
                        let checkedVariant = response.data.getselectedvariants.find(
                            res => res.id === cart.product_variant_id,
                        );
                        cart.discounted_type = checkedVariant.discounted_type;
                        cart.discounted_price = checkedVariant.discounted_price;
                        this.$store.dispatch('cart/updateCart', cart);
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
};

export default AddToCart;
