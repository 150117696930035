<template>
    <home-area-wrapper :header="$t('product.detail.youMayAlsoLike.header')">
        <div class="container">
            <div class="row mt-5 mb-5" v-if="products && products.data">
                <div
                    class="col-6 col-md-4 col-lg-3"
                    v-for="(product, index) in products.data"
                    :key="index"
                >
                    <div
                        @click="toDetailPage(product.slug)"
                        class="product-card pt-3 pb-3 position-relative w-100"
                    >
                        <div class="img-wrapper">
                            <img
                                v-if="product.photo"
                                :src="product.photo.file_url"
                                :alt="product.title"
                                class="img-fluid"
                            />
                        </div>
                        <div
                            class="
                                position-absolute
                                pt-2
                                pb-2
                                pe-3
                                ps-3
                                wish-icon
                            "
                            v-if="isLoggedIn"
                        >
                            <font-awesome-icon
                                v-if="hasInWishList(product.id)"
                                @click="toggleWish(product.id)"
                                :icon="['far', 'heart']"
                                class="active-icon"
                                :id="`active-wish-${product.id}`"
                            />
                            <font-awesome-icon
                                v-else
                                @click="toggleWish(product.id)"
                                :icon="['far', 'heart']"
                                :id="`active-wish-${product.id}`"
                            />
                        </div>
                        <p class="product-name text-center">
                            {{ product.title }}
                        </p>
                        <p class="product-price text-center">
                            {{
                                addCommaForPrice(
                                    product.productvariants[0].final_price,
                                )
                            }}
                            MMK
                        </p>
                    </div>
                </div>
            </div>
            <div class="text-center mb-5">
                <p>
                    {{
                        $tc('products.pagiStatus', current_count, {
                            c: total_count,
                        })
                    }}
                </p>
                <main-button
                    v-if="hasMorePages"
                    :name="$t('product.detail.youMayAlsoLike.button')"
                    class="view-all-btn"
                    @clicked="showMore"
                />
            </div>
        </div>
    </home-area-wrapper>
</template>

<script>
import HomeAreaWrapper from '../reusable/HomeAreaWrapper.vue';
import Button from '../reusable/Button';
import { mapGetters } from 'vuex';
import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';

// apollo query
import SimilarProducts from '@/graphql/queries/product/SimilarProducts.gql';

export default {
    mixins: [CommaPrice, AddToWishList],

    components: {
        'home-area-wrapper': HomeAreaWrapper,
        'main-button': Button,
    },

    data() {
        return {
            current_page: 1,
            current_count: 0,
            total_count: 0,
            hasMorePages: false,
        };
    },

    mounted() {
        this.getSimilarProducts();
    },

    watch: {
        '$route.params.slug': {
            handler: function() {
                this.current_count = 0;
                this.total_count = 0;
                this.getSimilarProducts();
            },
        },
    },

    computed: {
        ...mapGetters({
            products: 'product/getSimilarProducts',
        }),
    },

    methods: {
        toDetailPage(slug) {
            this.$router.push({
                name: 'ProductVariantDetail',
                params: {
                    slug: slug,
                },
            });
        },

        getSimilarProducts() {
            this.$apollo
                .query({
                    query: SimilarProducts,
                    fetchPolicy: 'no-cache',
                    variables: {
                        slug: this.$route.params.slug,
                        page: this.current_page,
                    },
                })
                .then(response => {
                    this.total_count =
                        response.data.product.similarproducts.paginatorInfo.total;
                    this.current_count =
                        this.current_count +
                        response.data.product.similarproducts.paginatorInfo
                            .count;
                    this.hasMorePages =
                        response.data.product.similarproducts.paginatorInfo.hasMorePages;

                    this.$store.commit(
                        'product/ADD_SIMILAR_PRODUCTS',
                        response.data.product.similarproducts,
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },

        showMore() {
            this.current_page++;

            this.$apollo
                .query({
                    query: SimilarProducts,

                    variables: {
                        slug: this.$route.params.slug,
                        page: this.current_page,
                    },
                })
                .then(response => {
                    this.total_count =
                        response.data.product.similarproducts.paginatorInfo.total;
                    this.current_count =
                        this.current_count +
                        response.data.product.similarproducts.paginatorInfo
                            .count;
                    this.hasMorePages =
                        response.data.product.similarproducts.paginatorInfo.hasMorePages;

                    const products = response.data.product.similarproducts.data;

                    products.map(product => {
                        this.$store.commit(
                            'product/APPEND_SIMILAR_PRODUCTS',
                            product,
                        );
                    });
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/components/productCard.scss';
.view-all-btn {
    padding: 1rem 5.5rem !important;
}
</style>
